<template>
  <CustomBottomSheet
    :refName="'filterSheet'"
    size="lg"
    :headerText="$t('filter')"
    :headerIcon="icon"
  >
    <div class="row">
      <CustomSelectBox
        :className="'col-md-12'"
        :id="`filterData-educationalGroupToken`"
        :value="filterData.educationalGroupToken"
        :options="educationalGroupTokenOptions"
        v-on:changeValue="filterData.educationalGroupToken = $event"
        :title="$t('EducationalGroups.name')"
        :imgName="'EducationalGroups.svg'"
      />

      <CustomSelectBox
        :className="'col-md-6'"
        :id="`filterData-oldEducationalGroupToken`"
        :value="filterData.oldEducationalGroupToken"
        :options="educationalGroupTokenOptions"
        v-on:changeValue="filterData.oldEducationalGroupToken = $event"
        :title="$t('EducationalGroupTransfers.oldGroup.name')"
        :imgName="'EducationalGroups.svg'"
      />
      <CustomSelectBox
        :className="'col-md-6'"
        :id="`filterData-newEducationalGroupToken`"
        :value="filterData.newEducationalGroupToken"
        :options="educationalGroupTokenOptions"
        v-on:changeValue="filterData.newEducationalGroupToken = $event"
        :title="$t('EducationalGroupTransfers.oldGroup.name')"
        :imgName="'EducationalGroups.svg'"
      />
    </div>

    <CustomBaseEntityFilter
      :className="'row'"
      :value="filterData"
      v-on:changeValue="filterData.fillData($event)"
    />

    <div class="form-actions">
      <div class="icon-submit" @click.prevent="search">
        <img src="@/assets/images/search-icon.svg" :title="$t('search')" />
      </div>
      <div @click.prevent="closeBottomSheet('filterSheet')" class="icon-cancel">
        <img src="@/assets/images/cancel-icon.svg" :title="$t('cancel')" />
      </div>
    </div>
  </CustomBottomSheet>
</template>

<script>
import CustomBottomSheet from "./../../../../components/general/CustomBottomSheet.vue";
import CustomBaseEntityFilter from "./../../../../components/general/CustomBaseEntityFilter.vue";
import CustomSelectBox from "./../../../../components/general/CustomSelectBox.vue";
import { getLanguage } from "./../../../../utils/functions";
import generalMixin from "./../../../../utils/generalMixin";
import icon from "@/assets/images/filter.svg";
import { getEducationalGroupsDialog } from "./../../../../utils/dialogsOfApi";

export default {
  mixins: [generalMixin],
  props: {
    theFilterData: {
      type: Object,
    },
  },
  data() {
    return {
      language: getLanguage(),
      filterData: this.theFilterData,
      educationalGroupTokenOptions: [],
      icon,
    };
  },
  components: {
    CustomBottomSheet,
    CustomBaseEntityFilter,
    CustomSelectBox,
  },
  methods: {
    async getEducationalGroupsDialog() {
      this.isLoading = true;
      this.educationalGroupTokenOptions = await getEducationalGroupsDialog();
      this.isLoading = false;
    },
    search() {
      this.closeBottomSheet("filterSheet");
      this.$emit("search", this.filterData);
    },
  },
  created() {
    this.getEducationalGroupsDialog();
  },
};
</script>
